import React from 'react';
import OrderDetailsModal from './OrderDetailsModal';
import RefundModal from './RefundModal';
import useModals from "../../store/modals"

const Index = () => {
    const modals = useModals((state) => state)

    return (
        <div className="modals-container">
            {modals.refundModal.visible && <RefundModal />}
            {modals.orderDetailsModal.visible && <OrderDetailsModal />}
        </div>
    );
}

export default Index;
