import React from 'react';

const Button = ({ text, onClick, Icon, id, variants }) => {
    return (
        <button className={`btn ${variants}`} id={id} onClick={() => onClick ? onClick() : null}>
            {Icon && <Icon />}
            <span>{text}</span>
        </button>
    );

}

export default Button;
