import React from 'react';
import ReactDOM from 'react-dom';
import { Routes } from './routes/Routes';
import reportWebVitals from './reportWebVitals';
import Modals from "./components/_modals"
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(
  <React.StrictMode>
    <Modals />
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();