import React from 'react';
import "./index.scss"
import PropTypes from 'prop-types';
import { RiSunFill, RiMoonFill } from 'react-icons/ri';

export const Switch = ({ id, value, onChange, ...props }) => {
  return (
    <div
      onClick={() => onChange(id, !value)}
      className={value ? 'switch on' : 'switch'}
      {...props}
    >
      <div className="status-ball" >
        {value ? <RiMoonFill /> : <RiSunFill />}
      </div>
    </div>
  );
}

Switch.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  value: PropTypes.bool,

  /**
   * Switch contents
   */
  id: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onChange: PropTypes.func,
};

Switch.defaultProps = {
  id: "default-switch",
  value: true,
  onChange: undefined,
};

