import create from 'zustand'
import { orders } from "./mocks"

const useGlobalState = create((set) => ({
    pages: 4,
    activePage: 1,
    resultsPerPage: 10,
    orders: [],
    filteredOrders: [],
    storeName: "",
    store: "",
    handleActivePage: (page) => set(() => ({
        activePage: page
    })),
    handleResultsPerPage: (results) => set(() => ({
        resultsPerPage: parseInt(results)
    })),
    setOrders: (orders) => set(() => ({
        orders: orders
    })),
    setFilteredOrders: (orders) => set(() => ({
        filteredOrders: orders
    })),
    setPages: (pages) => set(() => ({
        pages: pages
    })),
    setActivePage: (page) => set(() => ({
        activePage: page
    })),
    setStoreName: (storeName) => set(() => ({
        storeName: storeName
    })),
    setStore: (store) => set(() => ({
        store: store
    }))
}))

export default useGlobalState

