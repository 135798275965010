import React from 'react';
import useModals from '../store/modals';

//Components
import Button from "./_elements/Button"

//Assets
import { RiFileList3Fill, RiRefund2Fill } from "react-icons/ri";

const Order = ({ order }) => {
    const modals = useModals((state) => state)
    const { handleOrderDetailsModal, handleRefundModal } = modals;

    const handleOrderDetails = () => {
        handleOrderDetailsModal(order)
    }

    const handleOrderRefund = () => {
        handleRefundModal(order)
    }

    return (
        <div className="order row">
            <div className="col" id="order-number">#{order.order_number}</div>
            <div className="col" id="date">{new Date(order.logs[0].date).toLocaleDateString("es-ar")}</div>
            <div className="col" id="name">{order.contact.name}</div>
            <div className="col" id="total">${order.totalCost}</div>
            <div className={`col ${order.payment_status}`} id="status">
                {order.transaction.status === "pending" && <span>Pendiente</span>}
                {order.transaction.status === "paid" && <span>Pagado</span>}
                {order.transaction.status === "refunded" && <span>Reintegrado</span>}
            </div>
            <div className="col" id="actions">
                <Button
                    onClick={handleOrderDetails}
                    Icon={RiFileList3Fill}
                    text="Ver detalles"
                    id="see-details"
                />
                {/* {(order.transaction.status !== "refunded" && order.transaction.status !== "pending") && <Button
                    onClick={handleOrderRefund}
                    Icon={RiRefund2Fill}
                    text="Reintegrar"
                    id="refund-order"
                />} */}
            </div>
        </div>
    );
}

export default Order;
