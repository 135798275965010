import React from 'react';

//Assets
import KueskiPayImg from "../../assets/logo_kueski.png";
import TiendanubeImg from "../../assets/logo_tiendanube.png";

const Header = ({ storeName }) => {
    return (
        <div className="panel-header">
            <div className="logos">
                <img src={KueskiPayImg} alt="kueskiPay logo" />
                <img src={TiendanubeImg} alt="Tiendanube logo" />
            </div>
            <div className="text">
                Bienvenido, <span>{storeName}</span>
            </div>
        </div>
    );
}

export default Header;
