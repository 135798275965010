import KueskiPayImg from "../assets/logo_kueskiPay_color.png";
import TiendanubeImg from "../assets/logo_tiendanube.png";

const Logo = () => {
  return (
    <div className="logo-wrapper">
        <img src={KueskiPayImg} alt="KueskiPay logo" />
        <div className="separator"></div>
        <img src={TiendanubeImg} alt="Tiendanube logo" className="logo-tn" />
    </div>
  )
}

export default Logo