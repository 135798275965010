export const orders = [
    {
        "items": [
            {
                "id": 691751764,
                "name": "Producto Digital",
                "price": "1300.00",
                "quantity": 1,
                "quantityRefunded": 1,
                "free_shipping": false,
                "product_id": 99283678,
                "variant_id": 380093435,
                "thumbnail": "//d2r9epyceweg5n.cloudfront.net/assets/stores/img/no-photo-100-0.png",
                "variant_values": "",
                "sku": null,
                "properties": [],
                "url": "https://demoaguscemx4.mitiendanube.com/productos/producto-digital/?variant=380093435",
                "is_ahora_12_eligible": true
            }
        ],
        "logs": [
            {
                "event": "created",
                "date": new Date("2021-11-08T14:39:32.752Z")
            }
        ],
        "cart_id": "497845270",
        "store_id": "1437512",
        "order_number": "123",
        "status": "created",
        "currency": "MXN",
        "shippingAddress": {
            "first_name": "prueba",
            "last_name": "prueba",
            "zipcode": "01030",
            "address": "prueba",
            "number": "1212",
            "floor": "",
            "locality": "prueba",
            "city": "Álvaro Obregón",
            "state": "Ciudad de México",
            "country": "MX",
            "phone": "1453365343"
        },
        "contact": {
            "email": "prueba@prueba.com",
            "name": "Prueba Prueba",
            "phone": "1453365343"
        },
        "shippingCost": 0,
        "totalWithoutShipping": 1300,
        "totalCost": 1300,
        "successUrl": "https://demoaguscemx4.mitiendanube.com/checkout/v3/success/497845270/ba7a6792a1379177a7dd879e8b06267bec765d4c",
        "errorUrl": "https://demoaguscemx4.mitiendanube.com/checkout/v3/next/497845270/ba7a6792a1379177a7dd879e8b06267bec765d4c",
        "__v": 0,
        "externalId": "388046646284635",
        "transaction": {
            "events": [
                {
                    "transaction_id": "ffe0cfd0-4719-4ef8-893c-5b5edb118e78",
                    "amount": {
                        "value": "1300.00",
                        "currency": "MXN"
                    },
                    "type": "sale",
                    "status": "pending",
                    "failure_code": null,
                    "created_at": "2021-11-08T14:40:14.773458Z",
                    "happened_at": "2021-11-08T14:40:14.415Z",
                    "expires_at": null
                }
            ],
            "cart_id": "497845270",
            "store_id": "1437512",
            "transactionId": "ffe0cfd0-4719-4ef8-893c-5b5edb118e78",
            "payment_provider_id": "68027d07-93b2-4f3b-bf51-57a2f19a0ee9",
            "payment_method": {
                "type": "debit_card"
            },
            "captured_amount": {
                "value": "0.00",
                "currency": "MXN"
            },
            "refunded_amount": {
                "value": "1300.00",
                "currency": "MXN"
            },
            "info": {
                "refund_url": null,
                "external_id": "388046646284635",
                "external_url": "https://admin.kueski.com/orders/388046646284635",
                "ip": null
            },
            "status": "pending",
            "__v": 0
        }
    }
]