import { post as restPost, get as restGet } from "./shared/rest.service";

export const getStoreName = async (userId) => {
  try {
    const endpoint = `api/v1/panel/get-store-name?storeId=${userId}`;
    const result = await restGet(endpoint);

    console.log(result);
    return result.data;
  } catch (error) {
    return error;
  }
};

export const getOrders = async (userId) => {
  try {
    const endpoint = `api/v1/panel/get-orders?storeId=${userId}`;
    const result = await restGet(endpoint);

    console.log(result);
    return result.data;
  } catch (error) {
    return error;
  }
};

export const filterOrders = async (globalState, theFilter) => {
  let auxOrders = globalState.orders;

  if (theFilter.orderNumber !== "") {
    auxOrders = auxOrders.filter((order) =>
      `${order.order_number}`.includes(theFilter.orderNumber)
    );
  }

  if (theFilter.date !== null) {
    auxOrders = auxOrders.filter((order) =>
      order.logs[0].date
        .slice(0, 10)
        .includes(theFilter.date.toISOString().slice(0, 10))
    );
  }

  if (theFilter.status !== "") {
    auxOrders = auxOrders.filter((order) =>
      order.transaction.status.includes(theFilter.status)
    );
  }

  if (theFilter.customerName !== "") {
    auxOrders = auxOrders.filter((order) =>
      order.contact.name.includes(theFilter.customerName)
    );
  }

  if (theFilter.total !== "") {
    auxOrders = auxOrders.filter((order) =>
      order.totalCost.toString().includes(theFilter.total)
    );
  }

  return auxOrders;
};

export const refundOrder = async (order, state) => {
  try {
    const body = {
      orderId: order.cart_id,
      storeId: order.store_id,
      type: state.type,
      amount: state.type === "total" ? order.totalCost : state.amount,
      products: state.products.filter((product) => {
        if (product.quantitySelected > 0) {
          return product;
        }
      }),
    };

    console.log("Refunding Order");
    console.log(body);
  } catch (error) {
    return error;
  }
};
