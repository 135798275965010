import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { loginService } from "../services/onboarding.service";
import { showError, showSuccess } from "../services/shared/alert.service";
import { Spinner } from "./Spinner";

export const Form = ({ userId, storeUrl }) => {
  let history = useHistory();

  const [apikey, setApikey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (e) => {
    if (e.target.id == "apikey") {
      setApikey(e.target.value.trim());
    } else {
      setApiSecret(e.target.value.trim());
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (apikey && apiSecret) {
      try {
        const result = await loginService(apikey, apiSecret, userId);
        if (result.success) {
          showSuccess(result.message);
          window.location.href = `${storeUrl}/admin`;
        }
        if (!result.success) {
          showError(result?.message);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showError(error);
      }
    } else {
      setIsLoading(false);
      showError("Verifique haber completado todos los campos.");
    }
  };

  return (
    <form onSubmit={signIn}>
      <div className="input-wrapper">
        <label className="medium-text" htmlFor="apikey">
          Api key
        </label>
        <input
          className="medium-text"
          type="text"
          name="apikey"
          id="apikey"
          onChange={handleChange}
        />
      </div>
      <div className="input-wrapper">
        <label className="medium-text" htmlFor="apisecret">
          Api secret
        </label>
        <input
          className="medium-text"
          type="text"
          name="apiSecret"
          id="apiSecret"
          onChange={handleChange}
        />
      </div>
      {apikey && userId ? (
        <button className="btn" type="submit">
          {isLoading ? <Spinner /> : "Ingresar"}
        </button>
      ) : (
        <button disabled className="btn disabled" type="submit">
          Ingresar
        </button>
      )}
    </form>
  );
};
