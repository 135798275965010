import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import '../App.scss';

// Pages
import { Onboarding } from '../pages/Onboarding';
import { Panel } from '../pages/Panel';
import { WebComponent } from '../pages/WebComponent';
import { Loading } from '../pages/Loading';
require('dotenv').config();

export const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/panel" component={Panel} />
                <Route exact path="/onboarding" component={Onboarding} />
                <Route exact path="/web-component" component={WebComponent} />
                <Route exact path="/loading" component={Loading} />
            </Switch>
        </BrowserRouter>
    )
}
