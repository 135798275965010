import React from 'react';
import useGlobalState from "../../store/globalState";

const Footer = () => {
    const globalState = useGlobalState((state) => state)

    return (
        <div className="panel-body-footer">
            {/* Results Per Page */}
            <div className="results-per-page ">
                <select
                    value={globalState.resultsPerPage}
                    onChange={(e) => {
                        globalState.handleResultsPerPage(e.target.value)
                        globalState.setActivePage(1)
                    }}
                    name="results-per-page"
                    id="results-per-page"
                >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                Resultados por pagina
            </div>

            {/* Pagination */}
            <div className="pagination">
                {
                    new Array(globalState.pages).fill(0)
                        .map((page, index) => {
                            return (
                                <div
                                    className={
                                        globalState.activePage === (index + 1)
                                            ? "pagination-item active"
                                            : "pagination-item"
                                    }
                                    onClick={() => globalState.handleActivePage(index + 1)}
                                    key={index}>
                                    {index + 1}
                                </div>
                            )
                        })
                }

            </div>
        </div>
    );
}

export default Footer;
