import React, { useState, useEffect } from "react";
import useModals from "../../../store/modals";
import Modal from "../../_elements/Modal";
import Button from "../../_elements/Button";
import useGlobalState from "../../../store/globalState";

//Assets
import { RiCheckDoubleFill } from "react-icons/ri";
import { refundOrder, getOrders } from "../../../services/panel.service";

const RefundModal = () => {
  const globalState = useGlobalState((state) => state);
  const { order, product } = useModals((state) => state.refundModal);
  const { handleRefundModal } = useModals((state) => state);
  const [state, setState] = useState({
    amount: product ? 0 : order.totalCost,
    type: product ? "partial" : "total",
    products: [],
  });

  useEffect(() => {
    setState({
      ...state,
      products: order.items.map((prod) => {
        return {
          id: prod.id,
          quantity: prod.quantity,
          quantitySelected:
            product && prod.id === product.id ? prod.quantity : 0,
          price: prod.price,
          name: prod.name,
          image: prod.thumbnail,
        };
      }),
    });
  }, [order]);

  useEffect(() => {
    let refundAmount = 0;
    if (state.type === "partial") {
      state.products.forEach((product) => {
        refundAmount += product.quantitySelected * product.price;
      });
    } else {
      refundAmount = state.amount;
    }

    if (refundAmount !== state.amount) {
      setState({
        ...state,
        amount: refundAmount,
      });
    }
  }, [state]);

  const handleConfirm = async () => {
    try {
      console.log(state);
      await refundOrder(order, state);

      //Refetch orders
      const orders = await getOrders(globalState.store);
      globalState.setOrders(orders.reverse());
      handleRefundModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal id="refund-modal">
      <div className="modal-title">
        Reintegrando orden <span>#{order.order_number}</span>
      </div>
      <div className="modal-body">
        <div className="refund-type">
          <div
            onClick={() => setState({ ...state, amount: 0, type: "partial" })}
            className={
              state.type === "partial"
                ? "refund-type-item active"
                : "refund-type-item"
            }
          >
            Parcial
          </div>
          <div
            onClick={() => {
              let amount = parseFloat(order.totalCost);
              if (order.transaction.refunded_amount) {
                amount =
                  amount -
                  parseFloat(order.transaction.refunded_amount.value / 100);
              }
              setState({ ...state, type: "total", amount: amount });
            }}
            className={
              state.type === "total"
                ? "refund-type-item active"
                : "refund-type-item"
            }
          >
            Total
          </div>
        </div>
        {state.type === "partial" && (
          <div className="order-items">
            {state.products.map((product, i) => (
              <div className="order-item" key={product.id}>
                <div className="order-item-image">
                  <img src={product.image} alt={product.name} />
                </div>
                <div className="order-item-details">
                  <div className="order-item-name">{product.name}</div>
                  <div className="order-item-quantity">
                    <select
                      onChange={(e) => {
                        const quantity = parseInt(e.target.value);
                        const newState = {
                          ...state,
                          products: state.products.map((p, index) => {
                            if (i === index) {
                              return {
                                ...p,
                                quantitySelected: quantity,
                              };
                            }
                            return p;
                          }),
                        };
                        setState(newState);
                      }}
                      value={product.quantitySelected}
                      name="refund-quantity"
                      id="refund-quantity"
                    >
                      <option key={0}>{0}</option>
                      {new Array(parseInt(product.quantity))
                        .fill(0)
                        .map((p, i) => (
                          <option value={i + 1} key={i}>
                            {i + 1}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="order-item-price">${product.price}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="modal-footer">
        <div className="total-refund">
          Se reembolsarán <span>${state.amount}</span>
        </div>
        <Button
          onClick={() => handleConfirm()}
          Icon={RiCheckDoubleFill}
          variants="xl"
          id="confirm-refund"
          text="Confirmar Reintegro"
        />
      </div>
    </Modal>
  );
};

export default RefundModal;
