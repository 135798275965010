import React from 'react';
import { MdClose } from "react-icons/md";
import useModals from '../../store/modals';


const Modal = ({ children, id }) => {
    const modals = useModals((state) => state)
    const handleModal = () => {
        modals.handleModals(id)
    }

    return (
        <div className="modal" id={id}>
            <div className="card">
                <div onClick={() => handleModal()} className="close-button">
                    <MdClose />
                </div>
                {children}
            </div>
            <div onClick={() => handleModal(id)} className="overlay">

            </div>
        </div>
    );
}

export default Modal;
