import {
  post as restPost,
  get as restGet,
} from '../services/shared/rest.service';

export const loginService = async (apikey, apiSecret, userId) => {
  try {
    const endpoint = `api/v1/onboarding/install`;
    const body = {
      userApikey: apikey,
      userApiSecret: apiSecret,
      userId: userId,
    };
    const result = await restPost(endpoint, body);

    return result.data;
  } catch (error) {
    throw error.message;
  }
};

export const getShopName = async (urlCode) => {
  try {
    const endpoint = `api/v1/tiendanube/tienda-auth?code=${urlCode}`;
    const result = await restGet(endpoint);
    return result.data;
  } catch (error) {
    throw error.message;
  }
};
