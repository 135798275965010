import { post as restPost, get as restGet } from "./shared/rest.service";

export const getInitialState = async (userId) => {
  try {
    const endpoint = `api/v1/tiendaNube/web-component?storeId=${userId}`;
    const result = await restGet(endpoint);

    console.log(result);
    return result.data;
  } catch (error) {
    return error;
  }
};

export const saveWebComponent = async (userId, body) => {
  try {
    const endpoint = `api/v1/tiendaNube/web-component?storeId=${userId}`;
    const result = await restPost(endpoint, body);

    console.log(result);
    return result.data;
  } catch (error) {
    return error;
  }
};
