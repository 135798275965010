import React from "react";
import useModals from "../../../store/modals";
import Modal from "../../_elements/Modal";
import Button from "../../_elements/Button";

//Assets
import { RiRefund2Fill, RiPhoneFill, RiMailFill } from "react-icons/ri";

const OrderDetailsModal = () => {
  const { order } = useModals((state) => state.orderDetailsModal);
  const state = useModals((state) => state);

  const handleConfirm = () => {
    state.handleOrderDetailsModal();
    state.handleRefundModal(order);
  };

  return (
    <Modal id="order-details">
      <div className="modal-title">
        Detalles de pedido <span>#{order.order_number}</span>
      </div>
      <div className="modal-body">
        <div className="order-details">
          <div className="modal-section-title">Cliente</div>
          <div className="customer">
            <div className="customer-name">
              <span>Nombre: </span>
              {order.contact.name}
            </div>
            <div className="customer-contact">
              <span>Contacto: </span>
              <div className="customer-contact-wrapper">
                <div className="customer-contact-field">
                  <RiMailFill /> {order.contact.email}
                </div>
                <div className="customer-contact-field">
                  <RiPhoneFill />{" "}
                  {order.contact.phone ? order.contact.phone : "Sin teléfono"}
                </div>
              </div>
            </div>
            {order.shipping_address && (
              <div className="customer-address">
                <span>Dirección: </span>
                {order.shipping_address.address} {order.shipping_address.number}
                , {order.shipping_address.locality},{" "}
                {order.shipping_address.city}, {order.shipping_address.province}
              </div>
            )}
          </div>
          <div className="modal-section-title">Productos</div>
          <div className="order-items">
            {order.items.map((product) => (
              <div
                className={
                  product.quantityRefunded === product.quantity
                    ? "order-item refunded"
                    : "order-item"
                }
                key={product.id}
              >
                <div className="order-item-image">
                  <img src={product.thumbnail} alt={product.name} />
                </div>
                <div className="order-item-details">
                  <div className="order-item-name">{product.name}</div>
                  <div className="order-item-quantity">x{product.quantity}</div>
                  <div className="order-item-price">${product.price}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="order-total">
            <div className="total-wrapper">
              Total:
              {order.transaction.refunded_amount > 0 ? (
                <>
                  {order.transaction.refunded_amount.value > 0 && (
                    <span>
                      $
                      {parseFloat(order.totalCost) -
                        parseFloat(
                          order.transaction.refunded_amount.value / 100
                        )}
                    </span>
                  )}
                </>
              ) : (
                <span>${order.totalCost}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderDetailsModal;
