import create from 'zustand'

const useModals = create((set) => ({
    orderDetailsModal: { visible: false, order: null },
    refundModal: { visible: false, amount: 0, order: null, product: null },
    handleOrderDetailsModal: (order) => set((state) => ({
        orderDetailsModal: {
            visible: !state.orderDetailsModal.visible,
            order: order || state.orderDetailsModal.order
        }
    })),
    handleRefundModal: (order, product, amount) => set((state) => ({
        refundModal: {
            visible: !state.refundModal.visible,
            order: order || state.refundModal.order,
            product: product || null
        }
    })),

    handleModals: (id) => {
        switch (id) {
            case 'order-details':
                set((state) => ({
                    orderDetailsModal: {
                        visible: !state.orderDetailsModal.visible,
                        order: state.orderDetailsModal.order
                    }
                }))
                break;
            case 'refund-modal':
                set((state) => ({
                    refundModal: {
                        visible: !state.refundModal.visible,
                        order: state.refundModal.order,
                        product: state.refundModal.product
                    }
                }))
                break;
        }
    },
}))

export default useModals

