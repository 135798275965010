import Swal from 'sweetalert2';
import xIcon from '../../assets/x.svg';
import checkIcon from '../../assets/check.svg';

export const showError = async (message) => {

    Swal.fire({
        html: `<div class="swal-container-error">
        <img src=${xIcon} class="swal-icon" alt="red cross" />
        <h4 class="swal-title-error mb-0">Error</h4>
        <p class="swal-text mb-0">${ message ? message : 'Las credenciales ingresadas son incorrectas'}</p>
        </div>`,
        timer: 2000,
        showConfirmButton: false,
        background: 'hsla(0, 0%, 77%, 0)',
        backdrop: false,
        position: 'top-end',
        width: 0
    });
}


export const showSuccess = async (message) => {
    Swal.fire({
        html: `<div class="swal-container-success">
        <img src=${checkIcon} class="swal-icon" alt="green check" />
        <h4 class="swal-title-success mb-0">Éxito</h4>
        <p class="swal-text mb-0">${ message ? message : 'Se logró establecer el vínculo con Tiendanube'}</p>
        </div>`,
        timer: 2000,
        showConfirmButton: false,
        background: 'hsla(0, 0%, 77%, 0)',
        backdrop: false,
        position: 'top-end',
        width: 0
    });
}