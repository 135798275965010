import React from 'react';
import DatePicker from "react-datepicker";
import useGlobalState from "../../store/globalState";

const Bodyheader = ({ theFilter, setTheFilter }) => {
    const globalState = useGlobalState((state) => state)

    return (
        <div className="panel-body-header row">
            <div className="col" id="order-number">.
                <input onChange={(e) => {
                    setTheFilter({
                        ...theFilter,
                        orderNumber: e.target.value
                    })
                }} type="text" placeholder="N° de Órden" /></div>
            <div className="col" id="date">
                <DatePicker
                    dateFormat="P"
                    isClearable
                    selected={theFilter.date}
                    onChange={(date) => {
                        setTheFilter({
                            ...theFilter,
                            date: date
                        }
                        )
                        globalState.setActivePage(1)
                    }}
                    placeholderText="Fecha"
                    dateFormat="dd/MM/yyyy"
                />
            </div>
            <div className="col" id="name">
                <input
                    onChange={(e) => {
                        setTheFilter({
                            ...theFilter,
                            customerName: e.target.value
                        })
                        globalState.setActivePage(1)
                    }}
                    id="customer-name"
                    type="text"
                    placeholder="Nombre" />
            </div>
            <div className="col" id="total">
                <input onChange={
                    (e) => {
                        setTheFilter({
                            ...theFilter,
                            total: e.target.value
                        })
                        globalState.setActivePage(1)
                    }
                } type="number" name="total" id="total" placeholder="Total" />
            </div>
            <div className="col" id="status">
                <select onChange={
                    (e) => {
                        setTheFilter({
                            ...theFilter,
                            status: e.target.value
                        })
                        globalState.setActivePage(1)
                    }
                } name="status" id="status">
                    <option value="">{theFilter.status !== "" ? "Limpiar Estado" : "Estado"}</option>
                    <option value="paid">Pagado</option>
                    <option value="pending">Pendiente</option>
                    <option value="canceled">Cancelado</option>
                    <option value="refunded">Reintegrado</option>
                </select>
            </div>
            <div className="col" id="actions"></div>
        </div>
    );
}

export default Bodyheader;
