import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getShopName } from "../services/onboarding.service";

import { Form } from "../components/Form";
import { showError } from "../services/shared/alert.service";
import banner from "../assets/banner.png";
import bag from "../assets/bag.png";
import Logo from "../components/Logo";

export const Onboarding = () => {
  const [shopName, setShopName] = useState("");
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [storeUrl, setStoreUrl] = useState("");
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (!shopName) {
        setIsLoading(true);
        const { code = "" } = queryString.parse(location.search);
        const result = await getShopName(code);
        if (result && result.success) {
          setShopName(result.storeName);
          setUser(result.userId);
          setStoreUrl(result.storeUrl);
        }
        if (!result.success) {
          showError(result?.message);
        }
        setIsLoading(false);
      }
    })();
  }, [shopName]);

  return (
    <div className="main-container">
      <div className="left-wrapper">
        <Logo />
        <div className="form-wrapper">
          <div className="titles">
            <h2 className="form-title">
              Ingresa tus credenciales de Kueski Pay
            </h2>
            <h4 className="form-subtitle">
              Conecta tu cuenta de Kueski Pay con Tiendanube. ¿No sabes tu API
              Token?{" "}
              <a href="https://www.kueskipay.com/para-comercios/como-funciona#form-registro-merchant?utm_source=tiennu&utm_[…]mpaign=tiendanube&utm_term=ccn&utm_content=ih">
                Contactanos
              </a>
              .
            </h4>
          </div>
          <Form userId={user} storeUrl={storeUrl} />
          <p className="medium-text">
            Si todavía no tienes cuenta,{" "}
            <a
              href={
                "https://www.kueskipay.com/para-comercios/como-funciona#form-registro-merchant?utm_source=tiennu&utm_[…]mpaign=tiendanube&utm_term=ccn&utm_content=ih"
              }
              target="_blank"
              rel="noreferrer"
              className="btn-link"
            >
              Créala aquí.
            </a>
          </p>
        </div>
      </div>
      <div className="rigth-wrapper">
        <img src={banner} alt="banner" />
        <img src={bag} alt="bag" className="img-bag" />
      </div>
    </div>
  );
};
