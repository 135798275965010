import React, { useEffect, useState } from "react";
import {
  filterOrders,
  getOrders,
  getStoreName,
} from "../../services/panel.service";

//Components
import Order from "../../components/Order";
import BodyHeader from "./BodyHeader";
import Footer from "./Footer";
import Header from "./Header";

//Assets
import useGlobalState from "../../store/globalState";

function compare(a, b) {
  if (a.dateCreated > b.dateCreated) {
    return -1;
  }
  if (a.dateCreated < b.dateCreated) {
    return 1;
  }
  return 0;
}

export const Panel = () => {
  const globalState = useGlobalState((state) => state);
  const [theFilter, setTheFilter] = useState({
    orderNumber: "",
    date: null,
    status: "",
    total: "",
    customerName: "",
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const store = query.get("store");
    if (store) {
      globalState.setStore(store);
    }

    const newUrl = window.location.pathname;
    window.history.pushState({}, "", newUrl);
  }, []);

  useEffect(() => {
    const fetchStoreData = async () => {
      const store = globalState.store;
      if (store) {
        const { storeName } = await getStoreName(store);
        globalState.setStoreName(storeName);

        const orders = await getOrders(store);
        globalState.setOrders(orders.sort(compare).reverse());
      }
    };
    fetchStoreData();
  }, [globalState.store]);

  useEffect(async () => {
    if (globalState.orders && globalState.orders.length !== 0) {
      let auxOrders = await filterOrders(globalState, theFilter);

      let initPos =
        globalState.activePage -
        1 +
        (globalState.activePage - 1) * globalState.resultsPerPage;
      initPos =
        globalState.activePage === 1
          ? initPos
          : initPos - globalState.activePage;
      globalState.setPages(
        Math.ceil(auxOrders.length / globalState.resultsPerPage)
      );

      auxOrders = auxOrders.slice(
        initPos,
        initPos + globalState.resultsPerPage
      );
      globalState.setFilteredOrders(auxOrders);
    }
  }, [
    theFilter,
    globalState.activePage,
    globalState.pages,
    globalState.orders,
    globalState.resultsPerPage,
  ]);

  return (
    <div className="main-container" id="panel">
      <div className="panel-container">
        <Header storeName={globalState.storeName} />
        <div className="panel-body">
          <BodyHeader theFilter={theFilter} setTheFilter={setTheFilter} />
          <div className="panel-body-body">
            {globalState.filteredOrders.map((order) => (
              <Order key={order.id} order={order} />
            ))}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
