import { post as restPost } from '../services/shared/rest.service';

export const notifyTN = async (cartId, orderStatus) => {
    try {
        const endpoint = `api/v1/tiendaNube/notify-tn`;
        const body = {
            cartId: cartId,
            orderStatus: orderStatus
        };
        const result = await restPost(endpoint, body);

        return result.data;
    } catch(error) {
        return error;
    }
}