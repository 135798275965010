import React from "react";
import { useLocation } from "react-router-dom";
import KueskiPayImgBlack from "../assets/logo_kueski_black.png";
import Illustration from "../assets/illustration.svg";
import { Spinner2 } from "../components/Spinner";
import queryString from "query-string";
import { notifyTN } from "../services/loading.service";

export const Loading = () => {
  const location = useLocation();
  const {
    cartId = "",
    success = "",
    reject = "",
    canceled = "",
    failed = "",
  } = queryString.parse(location.search);

  setTimeout(() => {
    sendNotification();
  }, 2000);

  const sendNotification = async () => {
    const orderStatus = {
      success: setBoolean(success),
      reject: setBoolean(reject),
      canceled: setBoolean(canceled),
      failed: setBoolean(failed),
    };

    const result = await notifyTN(cartId, orderStatus);

    if (result.url) {
      window.location.href = result.url;
    }
  };

  function setBoolean(status) {
    if (status == "false") {
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="loading-main-container">
      <div className="loading-logo-wrapper">
        <img src={KueskiPayImgBlack} alt="kueskiPay logo" />
      </div>
      <div className="content-wrapper">
        <div className="welcome-text">
          <h1 className="loading-main-title">Aguarda</h1>
          <p className="loading-subtitle">
            Te redirigiremos al estado de tu compra
          </p>
        </div>
        <div className="illustration-container">
          <Spinner2 />
          <img src={Illustration} alt="work meeting" />
        </div>
      </div>
    </div>
  );
};
