import React, { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";

//Assets
import {
  getInitialState,
  saveWebComponent,
} from "../../services/webComponent.service";

// Components
import Button from "../../components/_elements/Button";
import { Switch } from "../../components/_elements/Switch";
const tooltipInitialState = {
  active: true,
};

const ConfigItemColor = ({ id, text, value, handleInput }) => {
  return (
    <div className="config-item">
      <div className="config-item__header">
        <div className="config-item__header__title">{text}</div>
      </div>

      <TwitterPicker
        color={value}
        onChangeComplete={(color) => handleInput(id, color.hex)}
      />
    </div>
  );
};

export const WebComponent = () => {
  const [tooltips, setTooltips] = useState(tooltipInitialState);
  const [store, setStore] = useState(() => localStorage.getItem("store") || "");
  const [state, setState] = useState({
    fontSize: "",
    textAlign: "",
    darkMode: false,
    active: {
      product: true,
      cart: true,
      checkout: true,
    },
  });

  const handleInputChange = (id, value) => {
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleTooltip = (id, value) => {
    setTooltips({
      ...tooltipInitialState,
      [id]: value,
    });
  };

  const Checkbox = ({ id, text }) => (
    <div className="checkbox">
      <input type="checkbox" name={id} id={id} checked={state.active[id]} />
      <label htmlFor={id}>{text}</label>
    </div>
  );

  const handleSave = async () => {
    const response = await saveWebComponent(store, state);
    if (response.success) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleDarkMode = () => {
    setState({
      ...state,
      darkMode: !state.darkMode,
    });
  };

  async function initialState(storeId) {
    const initialState = await getInitialState(storeId);
    setState({
      ...state,
      ...initialState,
    });
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const storeParam = query.get("store") || store;
    if (storeParam) {
      localStorage.setItem("store", storeParam);
      setStore(storeParam);
      const newUrl = window.location.pathname;
      window.history.pushState({}, "", newUrl);
    }
    initialState(storeParam);
  }, []);

  return (
    <div className="main-container" id="web-component">
      <div className="card">
        <div className="card__title">Configura tus widgets promocionales</div>

        <div className="config">
          <div className="config-item">
            <div className="config-item__header">
              <div className="config-item__header__title">Modo oscuro</div>
              <Switch value={state.darkMode} onChange={handleDarkMode} />
            </div>
          </div>
        </div>
        <div className="input-wrapper">
          <select
            name="fontSize"
            id="fontSize"
            value={state.fontSize}
            onChange={(e) => handleInputChange("fontSize", e.target.value)}
          >
            <option value="">Tamaño de fuente</option>
            <option value="12">12</option>
            <option value="14">14</option>
            <option value="16">16</option>
          </select>
          <select
            name="textAlign"
            id="textAlign"
            value={state.textAlign}
            onChange={(e) => handleInputChange("textAlign", e.target.value)}
          >
            <option value="">Alineacion texto</option>
            <option value="left">Izquierda</option>
            <option value="center">Centro</option>
            <option value="right">Derecha</option>
          </select>
        </div>
        <div className="input-wrapper">
          <input
            type="text"
            name="productUrl"
            id="productUrl"
            placeholder="Url de producto"
            value={state.productUrl}
            onChange={(e) => handleInputChange("productUrl", e.target.value)}
          />
        </div>
        <Button text="Guardar" onClick={handleSave} />
      </div>
      <div id="iframe">
        {state.productUrl ? (
          <iframe src={state.productUrl} frameborder="0"></iframe>
        ) : (
          <div className="iframe-placeholder">
            <div className="iframe-placeholder__title">
              Para ver los cambios del widget en tiempo real, primero debes
              guardar la url de algun producto en el campo{" "}
              <span>Url de producto</span> del menu lateral.
            </div>
            <div className="browser-mock-text">
              La url a copiar debe ser similar a la siguiente:
            </div>
            <div className="browser-mock">
              <img src="/kueski-product-link.png" alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
